import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {
    Button,
    Card,
    Container,
    createTheme,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import logo from '../assets/logos/logo-bi.png'
import {Alert} from "@mui/lab";
import {ArrowForward} from "@mui/icons-material";
import {sticketTheme} from "../theme/sticketTheme";
import {auth} from "../firebase/clientApp";

const Login = () => {
    document.body.style.backgroundColor = sticketTheme.palette.primary.main;
    const sx = {
        "& input": {
            textAlign: "left",
            color: "white",
        },
        "& focused": {
            border: `1px solid white`,
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
            background: '#ffffff45',
             borderRadius: '2rem'
        },
    }

    const md = useMediaQuery(sticketTheme.breakpoints.down('md'))

    let navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if(user) {
                //navigate('/0YMDHvtyguA3jahpOvpd/', { replace: true })
                navigate('/33zJbQxBTRrdnqibiGby/', { replace: true })
                window.location.reload();
            } else {
                navigate('/login', {replace:true})
            }
        })
    },[])

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                //authService.login(email, password).then(() => {
                    navigate('/33zJbQxBTRrdnqibiGby/', { replace: true })
                    //navigate('/0YMDHvtyguA3jahpOvpd/', { replace: true })
                    window.location.reload();
                //})
            })
            .catch((error) => {
                //console.log("error:",error)
                const resMessage = (error?.response &&
                        error?.response?.data &&
                        error?.response?.data?.message) ||
                    error?.message ||
                    error.toString();
                setLoading(false);
                setMessage("Ops, login non andato a buon fine...");
            });
    }

    const handleCloseError = () => setMessage("");

    return(
        <main>
            <Container sx={{textAlign:'center', marginTop: {sm: '20%', md: '6rem'}, paddingTop:'2rem'}}>
                <Stack direction={"column"} alignItems={"center"} textAlign={'center'} spacing={1.5}>
                    <img src={logo} width={md ? '45%' : '30%'}/>
                    <Typography gutterBottom color={'white'}>Inserisci le tue credenziali per accedere</Typography>
                    <TextField style={{maxWidth: '40%'}} sx={{...sx, my:1.5}} size={'small'} fullWidth placeholder={'Email'} onChange={handleSetEmail}/>
                    <TextField style={{maxWidth: '40%'}} sx={{...sx, mb:3}} size={'small'} fullWidth placeholder={'Password'} type={"password"} onChange={handleSetPassword}/>
                    <Button onClick={handleLogin}
                            variant={"submit-accent"}>
                        Login
                    </Button>
                    <Typography color={'secondary'} sx={{marginTop: 3}} variant={'body2'}>Password dimenticata?</Typography>
                </Stack>
                <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    )
}

export default Login
