import {Badge, Chip, LinearProgress, Stack} from "@mui/material";
import {DataGrid, itIT} from "@mui/x-data-grid";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import React, {useMemo, useRef, useState} from "react";
import useAxios from "axios-hooks";
import UserService from "../../services/user.service";
import {useNavigate, useParams} from "react-router-dom";
import ColumnsService from "../../services/columns.service";
import {useAuthStore} from "../../stores/AuthStore";
import {sticketTheme} from "../../theme/sticketTheme";
import {Add, Edit, FilterNone, LibraryAddCheck} from "@mui/icons-material";
import UserDetailsDialog from "./UserDetailsDialog";
import CustomTooltip from "../../components/CustomTooltip";
import {includes, remove} from "lodash";
import {QrCodeToPrintHandler} from "../../components/print/FunctionalComponent";
import UserMenuActions from "./user_details_view/UserMenuActions";
import DeletedUsersDialog from "./user_details_view/DeletedUsersDialog";

const UsersDashboard = () => {
    document.body.style.backgroundColor = sticketTheme.palette.background.default;

    const {tour_id} = useParams()

    const authState = useAuthStore.getState()
    //console.log("authState:",authState)

    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(10)

    const [{data: users, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )
    //console.log("users:",users)

    const columns = useMemo(() => {
        function handleOpenUserDetails(event, userId) {
            window.open(
                `users/${userId}`,
                "_blank",
                "noreferrer"
            )
            event.stopPropagation()
        }

        const actionCols = [
            {
                field: "actions",
                headerName: "Actions",
                align: "center",
                width: 80,
                sortable: false,
                renderHeader: () => <></>,
                renderCell: (params) => {
                    //console.log("params.row:",params.row)
                    return <Stack direction={'row'} alignItems={'center'}>
                        <CustomTooltip title={'Vedi utente'} children={
                            <UserDetailsDialog user={params.row} refresh={fetchUsers} icon={<Edit/>}/>
                        }/>
                       <UserMenuActions user={params.row} refresh={fetchUsers}/>
                        {/*<CustomTooltip title={'Vedi utente in una nuova tab'} children={
                            <IconButton onClick={(event) => handleOpenUserDetails(event, params.row.id)} children={
                                <OpenInNew/>
                            }/>
                        }/>*/}
                    </Stack>
                }
            }
        ]

        let baseCols = ColumnsService.getColumns({tour_id: tour_id, refresh: fetchUsers})
        remove(baseCols, ['field', 'meal'])
        //console.log("cols:", cols)
        return actionCols.concat(baseCols)
    }, [])

    const ref = useRef()

    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [userSelectionToPrint, setUserSelectionToPrint] = useState([]);

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState({
            createTime: false
        });

    return <Stack spacing={1}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} alignContent={'flex-end'}>
            <Stack direction={'row'} alignItems={'center'}>
                <Badge badgeContent={rowSelectionModel?.length} color={'secondary'}>
                    <CustomTooltip title={`${checkboxSelection ? 'Disattiva' : 'Attiva'} selezione multipla`}
                                   children={
                                       <Chip label={'Selezione multipla'}
                                             color={'primary'}
                                             sx={{px: 1, py: 2}}
                                             size={'small'}
                                             variant={checkboxSelection ? "filled" : 'outlined'}
                                             icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                                             onClick={() => setCheckboxSelection(!checkboxSelection)}
                                       />
                                   }/>

                </Badge>
                {rowSelectionModel.length > 0 &&
                    <QrCodeToPrintHandler
                        multiple={true}
                        buttonSize={'medium'}
                        qrCodes={rowSelectionModel}
                    />}
            </Stack>

            {/*<Typography fontWeight={'bold'}>Utenti aventi diritto ai pasti nella giornata/pasto selezionati</Typography>*/}
            <Stack ml={'auto'} direction={'row'} alignItems={'center'}>
                <UserDetailsDialog user={null} labelButton={'Aggiungi Utente'} icon={<Add/>} refresh={fetchUsers}/>
                <DeletedUsersDialog refetch={fetchUsers}
                />
            </Stack>
        </Stack>
        <DataGrid
            ref={ref}
            autoHeight
            localeText={{
                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                toolbarExport: "Esporta colonne visibili"
            }}
            loading={loadingUsers}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
            }
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={(newRowSelectionModel) => {
                //setRowSelectionModel(newRowSelectionModel);
                setRowSelectionModel(users.filter((o) => {return includes(newRowSelectionModel, o.id)})
                    .map((user) =>({
                        name: `${user?.name||''} ${user?.surname||''}`,
                        info: `${user.type === 'employee' ? '' : 'GUEST'} - ${user.company}`,
                        qrCode: user.qr_code_url, mealInfo: `${user.num_meals||''}`
                    })));
            }}
            disableSelectionOnClick
            /*onRowClick={(params, event, details) => {
                event.stopPropagation()
                //navigate(`users/${params.row.id}`, {state: {activeFormId: 'activeForm?.id'}});
                // TODO: Show row details
            }}*/
            components={{
                Toolbar: () => {
                    return <CustomGridToolbar/>
                },
                LoadingOverlay: LinearProgress,
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            columns={!loadingUsers ? (columns || []) : []}
            rows={users || []}
            //checkboxSelection={true}
        />
    </Stack>;
}

export default UsersDashboard