import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from 'react-router-dom'
import {auth} from "../firebase/clientApp";
import {useAuthStore, USER_ROLES} from "../stores/AuthStore";
import jwt_decode from "jwt-decode";

const ProtectedRoute = ({children}) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                //console.log("user:",user)
                const {role} = jwt_decode(await user.getIdToken())
                //console.log("onAuthStateChanged:",role)
                useAuthStore.setState((prev) => ({
                    user: user,
                    role: USER_ROLES[role] || role,
                }))
            } else {
                navigate('/login', {replace: true})
            }
        })
    },[])

    return children ? children : <Outlet/>;
};

export default ProtectedRoute
