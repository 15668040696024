import React from "react";
import {Typography} from "@mui/material";
import {ResponsiveBar} from "@nivo/bar";

const CustomBarMealsByCompany = ({toggleTourDatesStats, parsedMeals, tourDatesNames}) => {
    return (
        (parsedMeals && tourDatesNames) ?
            <ResponsiveBar
                data={parsedMeals}
                keys={toggleTourDatesStats ? tourDatesNames : ['total']}
                indexBy="company"
                groupMode="stacked"
                animate={false}
                layout={'horizontal'}
                margin={{top: 50, right: 80, bottom: 50, left: 300}}
                padding={0.3}
                valueScale={{type: 'symlog'}}
                indexScale={{type: 'band', round: true}}
                colors={{scheme: 'nivo'}}
                //colors={['gold', 'lightblue']}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 40,
                    legend: 'Pasti serviti',
                    legendPosition: 'middle',
                    legendOffset: 42
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -20,
                    legend: '',
                    //legendPosition: 'bottom',
                    //legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -30,
                        itemsSpacing: 5,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
            />
            : <Typography textAlign={'center'}>Dati insufficienti</Typography>
    );
}

export default CustomBarMealsByCompany