import React, {useEffect, useMemo, useState} from "react";
import {Autocomplete, Checkbox, Chip, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import SectionTitle from "../../../components/SectionTitle";
import useAxios from "axios-hooks";
import TourService from "../../../services/tour.service";
import {useParams} from "react-router-dom";
import CustomLoader from "../../../components/CustomLoader";
import CustomBarMealsByCompany from "./CustomBarMealsByCompany";
import {checkedIcon, icon} from "../../../services/utils";
import {AllInclusive, FilterNone, LibraryAddCheck, Tour} from "@mui/icons-material";
import {useTourStore} from "../../../stores/TourStore";
import {find} from "lodash";
import CustomTable from "../../../components/CustomTable";
import CustomDataGrid from "../../../components/CustomDataGrid";

const CompaniesSelection = ({
                                tour_id,
                                selectedCompanies,
                                toggleTourDatesStats,
                                setToggleTourDatesStats,
                                setSelectedCompanies
                            }) => {
    const [checkboxSelection, setCheckboxSelection] = useState(null)

    const [{data: companies, loading: loadingTourCompanies, error: errorTourCompanies}, fetchTourCompanies] = useAxios(
        TourService.tourDataUrl(tour_id) + "/companies", {method: "GET", useCache: false, manual: false})

    useEffect(() => {
        if (checkboxSelection === true)
            setSelectedCompanies(companies || [])
        else if (checkboxSelection === false)
            setSelectedCompanies([])
    }, [checkboxSelection])

    return <Paper component={Stack} p={2} mb={2} alignItems={'start'}>
        <Autocomplete
            id={"companies"}
            size={"small"}
            multiple
            limitTags={4}
            loading={loadingTourCompanies}
            disableCloseOnSelect
            options={companies || []}
            value={selectedCompanies}
            onChange={(event, newValues) => {
                setSelectedCompanies(newValues);
            }}
            sx={{width: "100%"}}
            renderInput={(params) => <TextField {...params} size={'small'} label="Filtra per Azienda"/>}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option}
                </li>
            )}
        />
        <Stack direction={'row'} alignItems={'center'}>
            <Chip label={'Tutto il tour'}
                  color={'primary'}
                  sx={{px: 1, py: 2, mt: 2}}
                  size={'small'}
                  variant={!toggleTourDatesStats ? "filled" : 'outlined'}
                  icon={<AllInclusive/>}
                  onClick={() => setToggleTourDatesStats(false)}
            />
            <Chip label={'Dividi per tappa'}
                  color={'primary'}
                  sx={{px: 1, py: 2, mt: 2, ml: 1}}
                  size={'small'}
                  variant={toggleTourDatesStats ? "filled" : 'outlined'}
                  icon={<Tour/>}
                  onClick={() => setToggleTourDatesStats(true)}
            />
            <Chip label={!checkboxSelection ? 'Seleziona tutte le aziende' : 'Deseleziona tutte le aziende'}
                  color={'primary'}
                  sx={{px: 1, py: 2, mt: 2, ml: 1}}
                  size={'small'}
                  variant={checkboxSelection ? "filled" : 'outlined'}
                  icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                  onClick={() => setCheckboxSelection(!checkboxSelection)}
            />
        </Stack>
    </Paper>;
}

const ReportByCompany = () => {
    const {tour_id} = useParams()
    const tourStore = useTourStore((state) => state)

    const [toggleTourDatesStats, setToggleTourDatesStats] = useState(false)

    const [selectedCompanies, setSelectedCompanies] =
        useState(tourStore?.companies_preassigned_meals?.map((company) => (company.label))||[])

    useEffect(() => {
        if(tourStore?.companies_preassigned_meals) {
            setSelectedCompanies(tourStore?.companies_preassigned_meals?.map((company) => (company.label)))
        }
    }, [tourStore])

    const [{data: tourDates, loading: loadingTourDates, error: errorTourDates}, fetchTourDates] = useAxios(
        TourService.tourDatesUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )

    const [{
        data: tourStatsByCompany,
        loading: loadingStatsByCompany,
        error: errorStatsByCompany
    }, fetchStatsByCompanies] = useAxios(
        `${TourService.tourDataUrl(tour_id)}/companies-stats?by_tour_date=${toggleTourDatesStats.toString()}`, {
            useCache: true,
            manual: true
        }
    )

    useEffect(() => {
        fetchStatsByCompanies({
            method: "POST",
            data: {companies: selectedCompanies}
        })
    }, [toggleTourDatesStats, selectedCompanies])

    const parsedMeals = useMemo(() => {
        if (!tourStatsByCompany)
            return null

        return Object.values(tourStatsByCompany)
    }, [tourStatsByCompany])

    const tourDatesNames = useMemo(() => {
        if (!tourDates)
            return null

        return tourDates.map((tourDate) => (tourDate.name))
    }, [tourDates])

    return (
        <Stack mt={12} height={`${(20 * parsedMeals?.length) + 400}px`}>
            <SectionTitle title={"Pasti per azienda"} color={'primary'}/>
            <CompaniesSelection tour_id={tour_id}
                                toggleTourDatesStats={toggleTourDatesStats}
                                setToggleTourDatesStats={setToggleTourDatesStats}
                                selectedCompanies={selectedCompanies}
                                setSelectedCompanies={setSelectedCompanies}/>
            {(loadingStatsByCompany || loadingTourDates) && <CustomLoader label={"L'operazione può richiedere qualche minuto..."}/>}
            {/*toggleTourDatesStats &&
                <Stack spacing={1}>
                    {parsedMeals?.map((stat) => (
                        <Stack direction={'row'} spacing={2}>
                            <Typography>{stat.company}</Typography>
                            {
                                tourDatesNames.map((date) => (
                                    <Chip label={`${date}: ${stat[date]} pasti`}/>
                                ))
                            }
                        </Stack>
                    ))
                    }
                </Stack>!toggleTourDatesStats &&
                parsedMeals?.map((stat) => {
                    const pastiPreventivati = find(tourStore?.companies_preassigned_meals, ['label', stat.company])
                    const diff = pastiPreventivati?.mealsCount - stat['total'] || 0
                    return <Grid mt={1} container justifyContent={'left'} alignItems={'center'} maxWidth={'50%'}>
                        <Grid item xs={4}>
                            <Typography variant={'body2'}>{stat.company}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip size={'small'} label={`${stat['total']} pasti consumati`}/>
                        </Grid>
                        {pastiPreventivati?.mealsCount &&
                            <Grid item xs={3}>
                                <Chip size={'small'} label={`${pastiPreventivati?.mealsCount||''} pasti preventivati`}/>
                            </Grid>}
                        {pastiPreventivati?.mealsCount &&
                            <Grid item xs={2}>
                                <Chip size={'small'} label={diff >= 0 ? `Credito pasti: ${diff}` : `Debito pasti: ${Math.abs(diff)}`}
                                      color={diff >= 0 ? 'success' : 'error'}
                                />
                            </Grid>}
                    </Grid>
                })
            */}
            {
                !toggleTourDatesStats &&
                <CustomDataGrid columns={['company', 'total', 'Preventivati', 'Differenza']}
                                rows={parsedMeals?.map((meal) => {
                                    const pastiPreventivati = find(tourStore?.companies_preassigned_meals, ['label', meal.company])
                                    const diff = pastiPreventivati?.mealsCount ?
                                        (pastiPreventivati?.mealsCount - (meal['total'] || 0))
                                        : '-'

                                    return {
                                        ...meal,
                                        Differenza: diff,
                                        Preventivati: pastiPreventivati?.mealsCount || '-'
                                    }})}/>
                /*<CustomTable columns={['company'].concat(tourDatesNames)} rows={parsedMeals}/>*/
            }
            {
                toggleTourDatesStats &&
                <CustomDataGrid columns={['company', 'total', 'Preventivati', 'Differenza'].concat(tourDatesNames)}
                                rows={parsedMeals?.map((meal) => {
                                    const pastiPreventivati = find(tourStore?.companies_preassigned_meals, ['label', meal.company])
                                    const diff = pastiPreventivati?.mealsCount ?
                                        (pastiPreventivati?.mealsCount - (meal['total'] || 0))
                                        : '-'

                                    return {
                                        ...meal,
                                        Differenza: diff,
                                        Preventivati: pastiPreventivati?.mealsCount || '-'
                                    }})}/>
                /*<CustomTable columns={['company'].concat(tourDatesNames)} rows={parsedMeals}/>*/
            }
            {
                parsedMeals && tourDatesNames &&
                <CustomBarMealsByCompany toggleTourDatesStats={toggleTourDatesStats} parsedMeals={parsedMeals}
                                         tourDatesNames={tourDatesNames}/>
            }
        </Stack>
    );
}

export default ReportByCompany
