import {createTheme} from "@mui/material";
import {itIT} from '@mui/material/locale';

export const APP_BAR_HEIGHT = 60;

export const sticketTheme = createTheme({
    typography: {
        fontFamily: ['Sharp Grotesk', 'sans-serif'].join(','),
    },

    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    ":disabled": {
                        color: "lightGray",
                        textDecoration: "none",
                        cursor: "default",
                        "&:hover": {
                            textDecoration: "none"
                        }
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: APP_BAR_HEIGHT,
                    borderRadius: 0
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "#91DCCC",
                    '&:disabled': {
                        color: "rgba(145,219,203,0.5)",
                    }
                }
            },
            variants: [
                {
                    props: {variant: 'secondary'},
                    style: {
                        color: "#30424b",
                        '&:disabled': {
                            color: "rgba(48,66,75,0.4)",
                        }
                    }
                },
            ]
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: 'dialog-button'},
                    style: {
                        color: '#F4FDFC',
                        "&:hover": {
                            color: "rgba(242,252,251,0.5)",
                        }
                    }
                },
                {
                    props: {variant: 'menu-selected'},
                    style: {
                        color: 'white',
                        backgroundColor: "#91DCCC",
                        paddingTop: 2,
                        paddingBottom: 2,
                    }
                },
                {
                    props: {variant: 'submit-primary'},
                    style: {
                        color: '#F4FDFC',
                        backgroundColor: "#070E2B",
                        paddingInline: '1rem',
                        paddingBlock: 3,
                        borderRadius: '2rem',
                        '&:disabled': {
                            backgroundColor: "rgba(7,14,43,0.5)",
                            boxShadow: 'none',
                        },
                        "&:hover": {
                            backgroundColor: "rgba(7,14,43,0.5)",
                        }
                    }
                },
                {
                    props: {variant: 'submit-accent'},
                    style: {
                        color: '#070E2B',
                        fontWeight: 'bold',
                        backgroundColor: "#91DCCC",
                        paddingInline: '2rem',
                        borderRadius: '2rem',
                        boxShadow: '1px 1px 6px 0px rgba(48,66,75,0.45)',
                        '&:disabled': {
                            backgroundColor: "rgba(145,219,203,0.5)",
                            boxShadow: 'none',
                        },
                        "&:hover": {
                            backgroundColor: "rgba(145,219,203,0.5)",
                        }
                    }
                }
            ]
        },
        MuiFab: {
            variants: [{
                props: {variant: 'action'},
                style: {
                    top: 'auto',
                    right: '20px',
                    bottom: '40px',
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1010,
                    backgroundColor: '#F9233B'
                }
            }]
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    //color: '#F4FDFC',
                    //borderRadius: '1.1rem',
                    //boxShadow: '1px 1px 6px 0px rgb(140 144 146 / 15%)'
                },
            },
            variants: [
                {
                    props: {variant: 'lighter-paper'},
                    style: {
                        background: '#1f2543',
                        padding: '1rem 2rem 3rem 2rem',
                    },
                },
            ]
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    background: 'rgba(48,66,75,0.95)',
                    color: '#F4FDFC',
                    marginBottom: '1rem',
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: '#F4FDFC',
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: '#1f2543',
                    color: '#F4FDFC',
                    borderRadius: '1.1rem',
                },

            },
            variants: [
                {
                    props: {variant: 'checkin'},
                    style: {
                        width: '100%',
                        top: '140px',
                    },
                },
            ]
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    width: '100% !important'
                }
            }
        },
        MuiChip: {
            variants: [
                {
                    props: {variant: 'accent-outlined'},
                    style: {
                        border: `1px solid #F9233B`,
                        backgroundColor: `transparent !important`,
                        color: `#F9233B !important`,
                        fontWeight: 'bold'
                    },
                },
            ]
        }
    },

    palette: {
        mode: 'light',
        primary: {
            main: '#070E2B',
        },
        secondary: {
            main: '#91DCCC',
        },
        background: {
            default: '#F6F9F8',
            //paper: '#1f2543'
        },
        accent: {
            main: '#F4FDFC',
        },
        text: {
            primary: '#070E2B',
        },
        error: {
            main: '#D96060'
        }
    },
    itIT,
});
