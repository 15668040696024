import React, {useEffect, useState} from "react";
import {AppBar, Box, Chip, Divider, Menu, Stack, Toolbar} from "@mui/material";
import logo_circle from "../assets/logos/logo-bi.png";
import IconButton from "@mui/material/IconButton";
import {AccountCircle, Restaurant, Tour} from "@mui/icons-material";
import AccountInfo from "./AccountInfo";
import {sticketTheme} from "../theme/sticketTheme";
import MenuItem from "@mui/material/MenuItem";
import LogoutComponent from "./LogoutComponent";
import {useTourStore} from "../stores/TourStore";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import TourService from "../services/tour.service";
import dayjs from "dayjs";

function CustomAppbar({children}) {
    const {tour_id} = useParams()

    const [{data: tour, loading: loadingTour, error: errorTour}, fetchTour] = useAxios(
        TourService.tourDataUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )

    useEffect(() => {
        if(tour) {
            useTourStore.setState((prev) => ({
                ...prev,
                ...tour
            }))
        }
    }, [tour])

    let interval
    useEffect(() => {
        function handleFetchTour() {
            console.log("handleFetchTour")
            fetchTour()
        }

        interval = setInterval(handleFetchTour, 1800000); //3600000, 1h

        return () => clearInterval(interval);
    }, [])

    const [anchorEl, setAnchorEl] = useState(null);

    const handleChange = (event) => {

    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Stack flexGrow={1} direction={'row'} alignItems={'center'} spacing={3}>
                        <img src={logo_circle} style={{maxWidth: '6rem'}}/>
                        {tour && <Stack mb={2} alignItems={'center'} direction={'row'} spacing={1}>
                            <Chip label={`${tour?.catering?.name}`}
                                  icon={<Restaurant/>} color={"secondary"} size={'small'}
                                  sx={{fontWeight: 'bold', maxWidth: '10rem'}}
                            />
                            <Chip
                                label={`${tour?.current_date?.name}: ${dayjs(tour?.current_date?.start_date).format('DD/MM')} - ${dayjs(tour?.current_date?.end_date).format('DD/MM')}`}
                                icon={<Tour/>} color={"secondary"} size={'small'}
                                //variant={'outlined'}
                                sx={{fontWeight: 'bold'}}
                            />
                        </Stack>}
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box pt={1} pl={2}>
                                <AccountInfo color={sticketTheme.palette.primary.main}/>
                            </Box>
                            <Divider/>
                            {/*<MenuItem onClick={handleClose}>Il mio profilo</MenuItem>*/}
                            <MenuItem><LogoutComponent label={'Logout'}/></MenuItem>
                        </Menu>
                    </Stack>
                </Toolbar>
            </AppBar>
            {children}
        </Box>
    );
}

export default CustomAppbar
