import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import React, {useState} from "react";
import {getUserPersonalLink, usersTypes} from "../services/utils";
import UserService from "../services/user.service";
import IconButton from "@mui/material/IconButton";
import {AlternateEmail, ContentCopy, Email, Link, Send, Share, WhatsApp} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    ClickAwayListener,
    Fade,
    Paper,
    Popper,
    Snackbar,
    Stack,
    TextField,
    ToggleButtonGroup
} from "@mui/material";
import CustomBackdrop from "./CustomBackdrop";
import {ToggleButton} from "@mui/lab";
import axios from "axios";

const CustomShareButton = ({enabled = true, alwaysOpen = false, user, refresh, size = 'medium', disablePortal = false}) => {
    const {tour_id} = useParams()

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const [state, setState] = useState({
        contactType: user?.contact || 'link',
        phone: user?.phone?.toString().replaceAll(/\s+/g, "") || '',
        email: user?.email || '',
        link: getUserPersonalLink(tour_id, user?.id)
    })

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClickShare = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        //setAnchorEl(null);
        setOpen(false);
    };


    const handleContactType = (event, newContactType,) => {
        if(!!newContactType && newContactType !== state.contactType)
            setState({...state, contactType: newContactType});
    };

    function handleChangeState(e) {
        setState({
            ...state,
            [e.target.id || e.target.name]: e.target.value?.replaceAll(/\s+/g, "")
        })
    }

    const handleUpdateUser = () => {
        updateUser({
            data: {
                contact: state.contactType,
                [state.contactType]: state[state.contactType]
            },
            url: UserService.userDataUrl(tour_id, user?.id),
            method: "PUT"
        })
            .then()
            .catch((err) => {
                console.log("err:",err)
                setMessage({
                    ...message,
                    show: true,
                    text: "Aggiornamento non andato a buon fine",
                    severity: "error"
                })
            })
    }

    return (<Box zIndex={10000}>
            <IconButton aria-label="share" size={size} onClick={handleClickShare} disabled={!enabled}>
                <Share fontSize={size}/>
            </IconButton>
            <Box>
                <CustomBackdrop open={loadingUpdate}/>
                <Popper open={alwaysOpen || open} anchorEl={anchorEl} placement={'top-end'} transition disablePortal={disablePortal}>
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper component={Stack} p={2} spacing={1.5} elevation={10}>
                                    <ToggleButtonGroup
                                        fullWidth
                                        size={'small'}
                                        value={state.contactType}
                                        exclusive
                                        onChange={handleContactType}
                                        aria-label="text alignment"
                                    >
                                        <ToggleButton value="link">
                                            <Link/>
                                        </ToggleButton>
                                        <ToggleButton value="email">
                                            <AlternateEmail/>
                                        </ToggleButton>
                                        <ToggleButton value="phone">
                                            <WhatsApp/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <TextField
                                        sx={{marginTop: 2}}
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                        type={state.contactType?.email ? 'email' : 'text'}
                                        id={state.contactType}
                                        name={state.contactType}
                                        disabled={state.contactType === 'link'}
                                        value={state[state.contactType]}
                                        onChange={(e) => handleChangeState(e)}
                                        label={state.contactType === 'link' ? 'Link' : 'Recapito'}

                                    />

                                    {
                                        state.contactType === 'link' ?
                                            <Button disabled={!state[state.contactType]}
                                                    endIcon={<ContentCopy/>}
                                                    variant={'submit-primary'}
                                                    onClick={() => navigator.clipboard.writeText(state.link)
                                                        .then(() => window.alert('Copiato con successo'))}
                                            >
                                                Copia
                                            </Button>
                                            : state.contactType === 'email' ?
                                                <Button disabled={!state[state.contactType]}
                                                        endIcon={<Send/>}
                                                        variant={'submit-primary'}
                                                        onClick={() => {
                                                            axios.post(`${UserService.userDataUrl(tour_id, user.id)}/sendEmail`, {
                                                                email: state[state.contactType]
                                                            })
                                                                .then(r => {
                                                                    setMessage({
                                                                        ...message,
                                                                        show: true,
                                                                        text: `Invio effettuato con successo`,
                                                                        severity: "success"
                                                                    })
                                                                    //refresh()
                                                                    handleUpdateUser()
                                                                })
                                                                .catch((err) => {
                                                                    console.log("err:",err)
                                                                    setMessage({
                                                                        ...message,
                                                                        show: true,
                                                                        text: "Invio non andato a buon fine",
                                                                        severity: "error"
                                                                    })
                                                            })
                                                        }}
                                                >
                                                    Invia E-mail
                                                </Button>
                                                : state.contactType === 'phone' ?
                                                    <Button disabled={!state[state.contactType]}
                                                            endIcon={<Send/>}
                                                            variant={'submit-primary'}
                                                            onClick={() => {
                                                                handleUpdateUser()
                                                                const msg = user.type === usersTypes.REFERENT.id ?
                                                                    `Al link che trovi di seguito puoi accedere alla gestione dei QR Guest a te assegnati tramite il tasto GESTISCI PASS . Puoi inviare 1 QR a ogni guest. Segna nelle note a chi l'hai inviato per sapere a chi l'hai assegnato.
                                                                    \n\nOgni QR sarà valido 1 volta a pasto.
                                                                    \n\n${getUserPersonalLink(tour_id, user.id)}`
                                                                    : `Al link che trovi di seguito puoi accedere al tuo QR code per ritirare i pasti in tour.
                                                                    \n\nIl QR sarà valido 1 volta a pasto.
                                                                    \n\n${getUserPersonalLink(tour_id, user.id)}`
                                                                const link = `https://wa.me/${state.phone}?text=${msg}`
                                                                //`https://web.whatsapp.com/send?phone=${state.phone}&text=${msg}`

                                                                window.open(
                                                                    link,
                                                                    "_blank",
                                                                    "noreferrer"
                                                                )
                                                            }}
                                                    >
                                                        Invia Messaggio
                                                    </Button>
                                                    : null

                                    }
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
                <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                           severity={message.severity}
                           sx={{width: '100%'}}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default CustomShareButton
