import React, {useMemo, useRef, useState} from "react";
import {
    Alert,
    Badge,
    Box, Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    LinearProgress,
    Stack
} from "@mui/material";
import CustomTooltip from "../../components/CustomTooltip";
import UserDetailsDialog from "./UserDetailsDialog";
import ColumnsService from "../../services/columns.service";
import {Add, AddTask, Edit, FilterNone, LibraryAddCheck, Restaurant} from "@mui/icons-material";
import {DataGrid, itIT} from "@mui/x-data-grid";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import {useParams} from "react-router-dom";
import {includes} from "lodash";
import {QrCodeToPrintHandler} from "../../components/print/FunctionalComponent";
import {sticketTheme} from "../../theme/sticketTheme";
import UserMenuActions from "./user_details_view/UserMenuActions";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import UserService from "../../services/user.service";
import MealService from "../../services/meal.service";
import {useSelectedMealStore} from "../../stores/SelectedMealStore";

const MealsDatagrid = ({selectedMealType, selectedMealsDate, mealsData, loadingMealsData, fetchMealsData}) => {
    const {tour_id} = useParams()
    const {currentDate, currentMeal} = useSelectedMealStore((state) => state)

    const [pageSize, setPageSize] = useState(10)

    const columns = useMemo(() => {
        function handleOpenUserDetails(event, userId) {
            window.open(
                `users/${userId}`,
                "_blank",
                "noreferrer"
            )
            event.stopPropagation()
        }

        const actionCols = [
            {
                field: "actions",
                headerName: "Actions",
                align: "center",
                width: 80,
                sortable: false,
                renderHeader: () => <></>,
                renderCell: (params) => {
                    //console.log("params.row:",params.row)
                    return <Stack direction={'row'} alignItems={'center'}>
                        <CustomTooltip title={'Vedi utente'} children={
                            <UserDetailsDialog user={params.row} refresh={fetchMealsData} icon={<Edit/>}/>
                        }/>
                        <UserMenuActions user={params.row} refresh={fetchMealsData} meals/>
                        {/*<CustomTooltip title={'Vedi utente in una nuova tab'} children={
                            <IconButton onClick={(event) => handleOpenUserDetails(event, params.row.id)} children={
                                <OpenInNew/>
                            }/>
                        }/>*/}
                    </Stack>
                }
            }
        ]

        //console.log("cols:", cols)
        return actionCols.concat(ColumnsService.getColumns({tour_id: tour_id, refresh: fetchMealsData}))
    }, [])

    const ref = useRef()

    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, responseMessage: ''});

    const handleToggleConfirmDialog = () => {
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            responseMessage: ''
        })
    }

    const handleAddMultipleManualMeal = () => {
        axios.post(`${MealService.mealsUrl(tour_id)}/manual?multiple=true`, {
                user_id: rowSelectionModel.map((r) => r.id),
                date: currentDate.toISOString(),
                meal: currentMeal
            })
            .then(r => {
                fetchMealsData(`${UserService.usersMealsDataUrl(tour_id, {
                    date: currentDate.format('YYYY-MM-DD'),
                    meal: currentMeal
                })}`)
                handleToggleConfirmDialog()
            })
            .catch(err => {
                console.log("err:",err)
            })
    }

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState({
            createTime: false
        });

    return (
        <Stack mt={2} spacing={1}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} alignContent={'flex-end'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Badge badgeContent={rowSelectionModel?.length} color={'error'}>
                        <CustomTooltip title={`${checkboxSelection ? 'Disattiva' : 'Attiva'} selezione multipla`}
                                       children={
                                           <Chip label={'Selezione multipla'}
                                                 color={'primary'}
                                                 sx={{px:1, py:2}}
                                                 size={'small'}
                                                 variant={checkboxSelection ? "filled" : 'outlined'}
                                                 icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                                                 onClick={() => setCheckboxSelection(!checkboxSelection)}
                                           />
                                       }/>
                    </Badge>
                    {rowSelectionModel.length > 0 &&
                        <Box borderRadius={'2rem'}
                             sx={{background: sticketTheme.palette.secondary.main}}
                        >
                            <QrCodeToPrintHandler
                                multiple={true}
                                color={'primary'}
                                qrCodes={rowSelectionModel}
                            />
                        </Box>}
                    {rowSelectionModel.length > 0 &&
                        <Box borderRadius={'2rem'}
                             sx={{background: sticketTheme.palette.secondary.main}}
                        >
                            <CustomTooltip title={'Segna manualmente pasto consumato'} children={
                                <IconButton size={'small'} onClick={handleToggleConfirmDialog}>
                                    <AddTask fontSize={'small'} color={'primary'}/>
                                    <Restaurant fontSize={'small'} color={'primary'}/>
                                </IconButton>
                            }/>
                            <Dialog open={openConfirmDialog.open} onClose={handleToggleConfirmDialog}>
                                <DialogContent>
                                    <DialogContentText>
                                        {`Sei sicuro di voler contrassegnare ${rowSelectionModel?.length} pasti come consumati?`}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant={'dialog-button'} onClick={handleToggleConfirmDialog}>{'Annulla'}</Button>
                                    <Button onClick={handleAddMultipleManualMeal} variant={'submit-accent'}
                                        //startIcon={<Check/>}
                                    >
                                        {'CONFERMA'}
                                    </Button>
                                </DialogActions>
                                {!!openConfirmDialog?.responseMessage && <Alert elevation={6} variant={"filled"}
                                                                                severity={'error'}
                                                                                sx={{width: '100%'}}>
                                    {openConfirmDialog?.responseMessage || ''}
                                </Alert>}
                            </Dialog>
                        </Box>}
                </Stack>

                {/*<Typography fontWeight={'bold'}>Utenti aventi diritto ai pasti nella giornata/pasto selezionati</Typography>*/}
                <Box ml={'auto'}>
                    <UserDetailsDialog user={null} labelButton={'Aggiungi Utente'} icon={<Add/>} refresh={fetchMealsData}/>
                </Box>
            </Stack>
            <DataGrid
                ref={ref}
                autoHeight
                localeText={{
                    ...itIT.components.MuiDataGrid.defaultProps.localeText,
                    toolbarExport: "Esporta colonne visibili",
                    noResultsOverlayLabel: "Nessun record trovato, prova la pagina dedicata alla gestione UTENTI"
                }}
                loading={loadingMealsData}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                checkboxSelection={checkboxSelection}
                onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(mealsData.filter((o) => {return includes(newRowSelectionModel, o.id)})
                        .map((user) =>({
                            id: user.id,
                            name: `${user?.name||''} ${user?.surname||''}`,
                            info: `${user.type === 'employee' ? '' : 'GUEST'} - ${user.company}`,
                            qrCode: user.qr_code_url, mealInfo: `${user.num_meals||''}`
                        })));
                }}
                disableSelectionOnClick
                /*onRowClick={(params, event, details) => {
                    event.stopPropagation()
                    //navigate(`users/${params.row.id}`, {state: {activeFormId: 'activeForm?.id'}});
                    // TODO: Show row details
                }}*/
                components={{
                    Toolbar: () => {
                        return <CustomGridToolbar selectedMealType={selectedMealType} selectedMealsDate={selectedMealsDate}/>
                    },
                    LoadingOverlay: LinearProgress,
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                columns={!loadingMealsData ? (columns || []) : []}
                rows={mealsData || []}
                //checkboxSelection={true}
            />
        </Stack>
    );
}

export default MealsDatagrid