import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {auth} from "../firebase/clientApp";
import IconButton from "@mui/material/IconButton";
import {Logout} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Stack} from "@mui/material";
import CustomTooltip from "./CustomTooltip";
import Typography from "@mui/material/Typography";

const LogoutComponent = ({label}) => {
    const navigate = useNavigate()

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    function handleOpenConfirmDialog() {
        setOpenConfirmDialog(!openConfirmDialog)
    }

    const handleLogout = () => {
        auth.signOut().then(() => {
            navigate('/login', {replace: true})
        }).catch((error) => {
            // An error happened.
        });
    }

    return <>
        <CustomTooltip
            title={"Logout"}
            children={
            <Stack direction={'row'} width={'100%'}
                   alignItems={'center'} justifyContent={label ? 'space-between' : 'center'}
                   onClick={handleOpenConfirmDialog}>
                {label && <Typography>
                    {label}
                </Typography>}
                <IconButton onClick={handleOpenConfirmDialog}
                            size={'small'}
                            children={<Logout fontSize={'small'}/>}
                />
            </Stack>}/>
        <Dialog open={openConfirmDialog} onClose={handleOpenConfirmDialog}>
            <DialogContent>
                <DialogContentText>
                    Sei sicuro di voler uscire?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    <Button variant={'dialog-button'} onClick={handleOpenConfirmDialog}>Annulla</Button>
                    <Button onClick={handleLogout} variant={'submit-accent'}
                            startIcon={<Logout/>}>Esci
                    </Button>
                </DialogActions>
            </DialogActions>
        </Dialog>
    </>;
}

export default LogoutComponent