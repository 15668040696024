import CustomDrawer from "../components/CustomDrawer";
import MealsDashboard from "./dashboard_view/MealsDashboard";
import {Route, Routes} from "react-router-dom";
import Typography from "@mui/material/Typography";
import React from "react";
import Reports from "./reports/Reports";
import UsersDashboard from "./dashboard_view/UsersDashboard";
import UsersDetails from "./dashboard_view/UserDetails";
import {useAuthStore, USER_ROLES} from "../stores/AuthStore";
import CateringView from "./catering_view/CateringView";
import CustomAppbar from "../components/CustomAppbar";
import CustomLoader from "../components/CustomLoader";
import {Stack} from "@mui/material";
import {Landing} from "../App";
import LogoutComponent from "../components/LogoutComponent";
import SticketSettings from "./settings/SticketSettings";

const Home = () => {
    const userRole = useAuthStore((state) => state.role)

    //console.log("userRole:",userRole)
    return (
        (userRole === USER_ROLES.AGENCY || userRole === USER_ROLES.ADMIN) ?
            <CustomDrawer children={
                <Routes>
                    <Route path="/*" element={<MealsDashboard/>}/>
                    <Route path="/reports" element={<Reports catering={false}/>}/>
                    <Route path="/users" element={<UsersDashboard/>}/>
                    <Route path="/users/:user_id" element={<UsersDetails/>}/>
                    <Route path="/settings" element={<SticketSettings/>}/>
                </Routes>
            }/>
            : userRole === USER_ROLES.CATERING ?
                <CustomAppbar children={
                    <Routes>
                        <Route path="/*" element={<CateringView/>}/>
                        <Route path="/reports" element={<Reports catering={true}/>}/>
                    </Routes>
                }/>
                : userRole === 'init' ? <CustomLoader/>
                    : <Stack justifyContent={'center'} alignItems={'center'}>
                        <Landing accessButton={false}/>
                        <Typography gutterBottom sx={{mt: 2}} variant={'caption'} color={'accent.main'}>Il tuo profilo non è configurato correttamente</Typography>
                        <LogoutComponent/>
                    </Stack>
    );
}

export default Home