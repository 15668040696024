import {create} from "zustand";
import {AdminPanelSettings, FoodBank, Groups} from "@mui/icons-material";

export const USER_ROLES = {
    AGENCY: {type: 'AGENCY', label: "Tour Manager", icon: <Groups color={'secondary'} /*fontSize={'small'}*//>},
    CATERING: {type: 'CATERING', label: "Ristoratore", icon: <FoodBank color={'secondary'} /*fontSize={'small'}*//>},
    ADMIN: {type: 'ADMIN', label: "Admin", icon: <AdminPanelSettings color={'secondary'} /*fontSize={'small'}*//>},
}

const initialUser = {
    user: null,
    role: 'init',
}

export const useAuthStore = create((set) => (
    {
        ...initialUser,
        logout: () => set(initialUser, true)
    }
))