import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import {Button, Stack, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import ProtectedRoute from "./components/ProtectedRoute";
import {sticketTheme} from "./theme/sticketTheme";
import logo from "./assets/logos/logo-bi.png";
import {useAuthStore} from "./stores/AuthStore";
import {auth} from "./firebase/clientApp";
import axios from "axios";
import {API_URL} from "./config";

export function Landing({accessButton = true}) {
    document.body.style.backgroundColor = sticketTheme.palette.primary.main;

    const user = useAuthStore((state) => state.user)

    const md = useMediaQuery(sticketTheme.breakpoints.down('md'))

    const navigate = useNavigate()
    function handleGoToLogin() {
        navigate('/login')
    }

    useEffect(() => {
        if(!user)
            handleGoToLogin()
    }, [user])

    return <Stack mt={{sm: '20%', md: '6rem'}}
                  pt={'2rem'}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={'center'}
                  textAlign={'center'}
                  spacing={1.5}>
        <img src={logo} width={md ? '45%' : '30%'}/>
        <Typography color={'secondary'} variant={'h5'} sx={{maxWidth: '30%', marginTop: '1.5rem'}}>
            Descrizione / Claim
        </Typography>
        {accessButton && <Button onClick={handleGoToLogin}
                 variant={"submit-accent"}>
            Accedi
        </Button>}
    </Stack>;
}

const App = () => {
    console.log("Developed by\n" +
        "\n" +
        "______ _           _                \n" +
        "| ___ \\ |         | |               \n" +
        "| |_/ / | ___  ___| |__    ___ ___  \n" +
        "|  __/| |/ _ \\/ __| '_ \\  / __/ _ \\ \n" +
        "| |   | |  __/\\__ \\ | | || (_| (_) |\n" +
        "\\_|   |_|\\___||___/_| |_(_)___\\___/ \n" +
        "                                    \n" +
        "                                    \n")

    axios.interceptors.request.use(
        async config => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    //33zJbQxBTRrdnqibiGby
    return (
        <Routes>
            <Route path={'/'} element={<Landing/>}/>
            <Route path={'/login'} element={<Login/>}/>
            <Route element={<ProtectedRoute/>}>
                <Route path="/:tour_id/*" element={<Home/>}/>
            </Route>
        </Routes>
    );
}

export default App;
