import {Stack, Typography} from "@mui/material";
import {sticketTheme} from "../theme/sticketTheme";
import CustomDivider from "./CustomDivider";
import React from "react";

const SectionTitle = ({title, color = sticketTheme.palette.accent.main}) => {
    return <Stack marginTop={1.5}>
        <Typography color={color} variant={"h6"} fontWeight={'bold'}>
            {title}
        </Typography>
        <CustomDivider/>
    </Stack>;
}

export default SectionTitle