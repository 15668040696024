import * as React from "react";
import {sticketTheme} from "../../theme/sticketTheme";
import {Restaurant} from "@mui/icons-material";
import logo from "../../assets/logos/LOGO_nero_Plesh.png"

export class ComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { name, info, qrCode, mealInfo } = this.props;

        return (
            <div style={{
                padding: '4px',
                maxWidth: '218px',
                border: `5px solid ${sticketTheme.palette.secondary.main}`,
                borderRadius: '1rem',
                marginRight: '2px',
                marginBottom: '4px',
                paddingTop: '20px',
            }}>
                <style type="text/css" media="print">
                    {"\
   @page { size: a4 portrait; }\
"}
                </style>
                <img src={logo} height={'15px'} style={{objectFit: 'cover', maxWidth: '100px'}}/>
                <img alt="qr" src={qrCode} width="200" height="200"
                     style={{borderRadius: '0.5rem'}}
                />
                <div style={{textAlign: 'center', width: 200, display: 'grid', fontSize: '1rem', paddingInline: '4px'}}>
                    {/*<div style={{height: '4px', background: sticketTheme.palette.secondary.main}}/>*/}
                    <strong>{name}</strong>
                    <strong>{info}</strong>
                    {mealInfo ? <strong>{mealInfo || ''} <Restaurant/></strong> : null}
                </div>
            </div>
        );
    }
}

export class MultipleComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { qrCodes } = this.props;
        //console.log("qrCodes:",qrCodes)

        return (<div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                margin: '20px',
                padding: '10px'
            }}>
            {qrCodes.map((qr, i) => (
                <div key={i} style={{
                    padding: '4px',
                    maxWidth: '218px',
                    border: `5px solid lightgrey`,
                    //borderRadius: '1rem',
                    marginRight: '2px',
                    marginBottom: '4px',
                    paddingTop: '20px'
                }}>
                    <style type="text/css" media="print">
                        {"\
   @page { size: a4 portrait; }\
"}
                    </style>
                    <img src={logo} height={'15px'} style={{objectFit: 'cover', maxWidth: '100px'}}/>
                    <img alt="qr" src={qr.qrCode} width="200" height="200"
                         style={{borderRadius: '0.5rem'}}
                    />
                    <div style={{textAlign: 'center', width: 200, display: 'grid', fontSize: '1rem', paddingInline: '4px'}}>
                        {/*<div style={{height: '4px', background: sticketTheme.palette.secondary.main}}/>*/}
                        <strong>{qr.name}</strong>
                        <strong>{qr.info}</strong>
                        {qr.mealInfo ? <strong>{qr.mealInfo || ''} <Restaurant/></strong> : null}
                    </div>
                </div>
            ))
            }</div>
        );
    }
}

export const QrCodeToPrint = React.forwardRef((props, ref) => {
    // eslint-disable-line max-len
    return <div style={{display: 'none'}}>
        {props.multiple ?
            <MultipleComponentToPrint ref={ref}
                                      qrCodes={props.qrCodes}
            />
            : <ComponentToPrint ref={ref} name={props.name}
                              info={props.info} mealInfo={props.mealInfo}
                              qrCode={props.qrCode}
            />}
    </div>;
});
