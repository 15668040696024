import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {DataGrid, GridToolbar, itIT} from "@mui/x-data-grid";
import {includes, isNumber, startCase} from "lodash";
import CustomGridToolbar from "./data-grid/CustomGridToolbar";
import {Chip, LinearProgress} from "@mui/material";
import clsx from "clsx";
import {Check, Close, PersonPinCircle} from "@mui/icons-material";

export default function CustomDataGrid({columns, rows}) {
    return (
        <DataGrid
            autoHeight
            localeText={{
                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                toolbarExport: "Esporta colonne visibili",
                noResultsOverlayLabel: "Nessun record trovato, prova la pagina dedicata alla gestione UTENTI"
            }}
            disableSelectionOnClick
            columns={columns?.map((col, i) => ({
                field: col,
                headerName: startCase(col),
                flex: col === 'company' ? 2 : 1,
                id: col,
                renderCell: (params) => {
                    return params.field === 'Differenza' ?
                        isNumber(params.value) ?
                            <Chip size={'small'} label={params.value >= 0 ? `${params.value}`
                                : `${params.value}`} color={params.value >= 0 ? 'success' : 'error'}/>
                        : params.value : params.value

                }
            })) || []}
            rows={rows?.map((row, i) => ({...row, id: i})) || []}
            density={'compact'}
            components={{
                Toolbar: () => {
                    return <GridToolbar/>
                },
                LoadingOverlay: LinearProgress,
            }}
        />
    );
}