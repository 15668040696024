import {Box, Fab, Stack} from "@mui/material";
import React from "react";
import {DocumentScanner, Insights} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import QrCodesScanner from "./QrCodeScanner";
import LastMealsDialog from "./LastMealsDialog";
import Typography from "@mui/material/Typography";
import {useTourStore} from "../../stores/TourStore";

const CateringView = () => {
    const navigate = useNavigate()

    const tourName = useTourStore((state) => state.name)

    return (
        <Stack>
            <Typography sx={{marginTop: 1}} textAlign={'center'} fontWeight={'bold'}>{tourName || ''}</Typography>
            <QrCodesScanner/>
            <Fab variant={'extended'}
                 size={'large'}
                 color={'primary'}
                 sx={{fontWeight: 'bold', fontSize: '1rem', marginX: '20%',
                     position: 'absolute',
                     bottom: 90,
                     right: 0,
                     left: 0,}}
                 onClick={() => navigate("reports")}
            >
                <Insights fontSize={'medium'} sx={{mr: 1}}/>
                DASHBOARD
            </Fab>
            <Box position={'absolute'} bottom={32} left={0} right={0}>
                <LastMealsDialog labelButton={'Vedi le ultime scansioni'}
                                 icon={<DocumentScanner fontSize={'large'}/>}/>
            </Box>
        </Stack>
    );
}

export default CateringView
